

export const GET_REACTION = "GET_REACTION";

export const ADD_REACTION = "ADD_REACTION";

export const DELETE_REACTION = "DELETE_REACTION";

export const UPDATE_REACTION = "UPDATE_REACTION";



export const OPEN_REACTION_DIALOG = "OPEN_REACTION_DIALOG";
export const CLOSE_REACTION_DIALOG = "CLOSE_REACTION_DIALOG";
