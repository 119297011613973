import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_REASON_DIALOGUE } from "../../../store/hostRequest/type";
import { acceptHostRequest } from "../../../store/hostRequest/action";

const ReasonDialogue = () => {
  const dispatch = useDispatch();
  const {
    dialog: open,
    dialogData,
    agency,
  } = useSelector((state) => state.hostRequest);

  const [reason, setReason] = useState();
  const [errors, setErrors] = useState("");

  const closePopup = () => {
    dispatch({ type: CLOSE_REASON_DIALOGUE });
  };
    const handleSubmit = () => {
      if(!reason || !dialogData.id){
        if(!reason) setErrors("Reason can't be a blank!")
        if(!dialogData) setErrors("Id not found!")
      }
      dispatch(acceptHostRequest(dialogData.id, "decline",reason));
  };
  console.log('dialogData', dialogData)


  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
         
            width: "100%", // Full width of the container
            margin: "0 auto", // Ensure it's centered horizontally
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4">
            {" "}
            Reason Dialog{" "}
          </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div class="form-group col-12 mY-3">
            <label class="mb-2 text-gray">Reason</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Reason"
              required
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);

                if (!e.target.value) {
                  return setErrors("Reason can't be a blank!");
                } else {
                  return setErrors("");
                }
              }}
            />
            {errors && (
              <div className="ml-2 mt-1">
                {errors.reason && (
                  <div className="pl-1 text__left">
                    <span className="text-red">{errors}</span>
                  </div>
                )}
              </div>
            )}

            <div className={"my-3 pt-3"}>
              <button
                type="button"
                className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                onClick={closePopup}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-round float__right btn-danger"
              onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReasonDialogue;
