export const GET_COIN_PLAN = "GET_COIN_PLAN";
export const CREATE_NEW_COIN_PLAN = "CREATE_NEW_COIN_PLAN";
export const EDIT_COIN_PLAN = "EDIT_COIN_PLAN";
export const DELETE_COIN_PLAN = "DELETE_COIN_PLAN";

export const OPEN_COIN_PLAN_DIALOG = "OPEN_COIN_PLAN_DIALOG";
export const CLOSE_COIN_PLAN_DIALOG = "CLOSE_COIN_PLAN_DIALOG";

export const GET_COIN_PLAN_HISTORY = "GET_COIN_PLAN_HISTORY";

export const ISTOP_SWITCH_TOGGLE = "ISTOP_SWITCH_TOGGLE";
