export const GET_ADMISSION_CAR_GIF =  "GET_ADMISSION_CAR_GIF"  

export const OPEN_DIALOGUE_ADMISSION_CAR  = "OPEN_DIALOGUE_ADMISSION_CAR"

export const CLOSE_DIALOGUE_ADMISSION_CAR  = "CLOSE_DIALOGUE_ADMISSION_CAR"

export const CERATE_ADMISSION_CAR_GIF =  "CERATE_ADMISSION_CAR_GIF"  

export const UPDATE_ADMISSION_CAR_GIF =  "UPDATE_ADMISSION_CAR_GIF"  

export const DELETE_ADMISSION_CAR_GIF =  "DELETE_ADMISSION_CAR_GIF"  





