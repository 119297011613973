//Get Entire Settlement
export const GET_ALL_SETTLEMENT = "GET_ALL_SETTLEMENT"

//Get Agency Settlement
export const GET_AGENCY_SETTLEMENT = "GET_AGENCY_SETTLEMENT"

//Get Agency Host Settlement
export const GET_AGENCY_HOST_SETTLEMENT = "GET_AGENCY_HOST_SETTLEMENT"

export const UPDATE_PAID_SETTLEMENT = "UPDATE_PAID_SETTLEMENT"

export const ACTION_UPDATE_PENDING = "ACTION_UPDATE_PENDING"

export const GET_ALL_WEEKLY_SETTLEMENT = "GET_ALL_WEEKLY_SETTLEMENT"

export const OPEN_DIALOGUE_SETTLEMENT = "OPEN_DIALOGUE_SETTLEMENT"
export const CLOSE_DIALOGUE_SETTLEMENT = "CLOSE_DIALOGUE_SETTLEMENT"